import Vue from 'vue';
import App from './App.vue';
import router from './routes/router';
import DashboardPlugin from './plugins/dashboard-plugin';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import i18n from './i18n'
import './util/vee-validate'

Vue.config.productionTip = false;

Vue.use(DashboardPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
