<template>
  <b-nav-item-dropdown :text="$t('language')" right>
    <b-dropdown-item @click="setLocale('de')">Deutsch</b-dropdown-item>
    <b-dropdown-item @click="setLocale('en')">English</b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
export default {
  created() {
    const locale = window.localStorage.getItem('locale');
    if(locale) {
      this.setLocale(locale);
    }
  },
  methods: {
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        window.localStorage.setItem('locale', locale);
      }
    },
  },
};
</script>
