<template>
  <b-container>
    <b-row>
      <b-navbar class="px-6" :sticky="true">
        <b-navbar-brand class="navbar-brand" href="https://www.myhermes.at/privatkunden/uebersicht/" target="_blank">
          <img src="@/assets/logo.svg" class="navbar-brand-img" alt="Hermes" />
        </b-navbar-brand>
      </b-navbar>
    </b-row>
  </b-container>
</template>
<script>

export default {
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
  },
};
</script>
