import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import { RouteConfig } from "vue-router";
// import AuthLayout from '@/views/Pages/AuthLayout.vue';

import NotFound from "@/views/NotFoundPage.vue";

const routes: Array<RouteConfig> = [
  // {
  //   path: "/",
  //   redirect: "retourenportal",
  //   component: DashboardLayout,
  // },
  // {
  //   path: "/retourenportal",
  //   name: "companies",
  //   component: DashboardLayout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../views/Pages/CompaniesList.vue')
  //     },
  //     {
  //       path: ':companyName',
  //       name: 'returnLabelForm',
  //       component: () => import('../views/Pages/ReturnLabelForm.vue'),
  //     },
  //     { path: '*', component: NotFound }
  //   ]
  // }
  {
    path: '/:companyName',
    name: 'returnLabelForm',
    component: DashboardLayout,
    children: [
      {
        path: '',
        component: () => import('../views/Pages/ReturnLabelForm.vue')
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
