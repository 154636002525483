<template>
  <footer class="footer px-4 mt-auto">
    <b-container>
      <b-row align-v="center" class="justify-content-lg-between text-center">
        <b-col lg="8">
          <div class="text-lg-left footer-logo">
            <a href="https://myhermes.at" class="font-weight-bold ml-1" target="_blank"><img src="@/assets/logo.svg" class="navbar-brand-img" alt="Hermes" /></a> 
          </div>
        </b-col>
        <b-col lg="4">
          <div class="text-lg-left text-muted footer-links d-flex justify-content-center flex-column flex-lg-row mt-lg-0 mt-3">
            <a href="https://www.myhermes.at/metanavigation/impressum/" class="font-weight-bold mr-lg-3 pb-lg-0 pb-1" target="_blank">Impressum</a>
            <a href="https://www.myhermes.at/metanavigation/datenschutz/" class="font-weight-bold" target="_blank">Datenschutz</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
