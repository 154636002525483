import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import i18n from "../i18n";

extend('required', {
  ...required,
  message: (_, values) => i18n.t(`validation.${values._rule_}`, {_field_: values._field_}),
});

extend('regex', {
  ...regex,
  message: (_, values) => i18n.t(`validation.${values._rule_}`, {_field_: values._field_}),
});